import React, { Fragment, useState, useContext } from "react"
import { LoadingContext } from "../context/LoadingContext"
import AxaColpatria from "../../images/xMediaSuccessStories/Stories/AxaColpatria.png"
import Dafiti from "../../images/xMediaSuccessStories/Stories/Dafiti.png"
import Education from "../../images/xMediaSuccessStories/Stories/Education.png"
import Finance from "../../images/xMediaSuccessStories/Stories/Finance.png"
import Gapsi from "../../images/xMediaSuccessStories/Stories/Gapsi.png"
import Chubb from "../../images/xMediaSuccessStories/Stories/Chubb.png"
import i18n from "i18next"
import { Link } from "@reach/router"

function StoriesTargets() {
  const { windowWidth } = useContext(LoadingContext)

  const stories = [
    {
      img: AxaColpatria,
      title: i18n.t("pageMediaSuccessStories.axaColpatria.title"),
      subtitle: i18n.t("pageMediaSuccessStories.axaColpatria.subtitle"),
      page: "axaColpatria"
    },
    {
      img: Dafiti,
      title: i18n.t("pageMediaSuccessStories.dafiti.title"),
      subtitle: i18n.t("pageMediaSuccessStories.dafiti.subtitle"),
      page: "dafiti"
    },
    {
      img: Education,
      title: i18n.t("pageMediaSuccessStories.education.title"),
      subtitle: i18n.t("pageMediaSuccessStories.education.subtitle"),
      page: "education"
    },
    {
      img: Finance,
      title: i18n.t("pageMediaSuccessStories.finance.title"),
      subtitle: i18n.t("pageMediaSuccessStories.finance.subtitle"),
      page: "finance"
    },
    {
      img: Gapsi,
      title: i18n.t("pageMediaSuccessStories.gapsi.title"),
      subtitle: i18n.t("pageMediaSuccessStories.gapsi.subtitle"),
      page: "gapsi"
    },
    {
      img: Chubb,
      title: i18n.t("pageMediaSuccessStories.chubb.title"),
      subtitle: i18n.t("pageMediaSuccessStories.chubb.subtitle"),
      page: "chubb"
    }
  ]

  return (
    <div className={`flex flex-wrap desktop:px-20 mt-20 mb-20 ${windowWidth < 1025 ? "flex-col" : ""}`}>
      {stories.map((story, index) => (
        <div
          key={index}
          className={`w-${windowWidth < 1025 ? "full" : "1/3"} p-4`}
        >
          <div
            className="relative overflow-hidden"
          >
            <Link to={`/open-success-stories/${story.page}`}>    
            <img
              src={story.img}
              alt={story.title}
              className="w-full h-full object-cover"
            />
            <div
                className="absolute bottom-0 left-0 right-0 p-6 shadowProjectImg"
              >
                <div className="absolute bottom-0 p-4 w-full from-black to-transparent text-left">
                <p className="titleTargetSuccess">{story.title}</p>
                <p className={ windowWidth < 1025 ? "subtitleTargetSuccess marginBSuccessMobile": "subtitleTargetSuccess marginBSuccess"}>{story.subtitle}</p>
                </div>
            </div>
            </Link>
          </div>
        </div>
      ))}
    </div>
    )

}

export default StoriesTargets