import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import imageBanner from "../../src/images/xMediaSuccessStories/BannerSuccessSotires.png"
import BannerXMmediaSuccessStories from "../components/xMediaSuccessStories/BannerXMediaSuccessStories"
import StoriesTargets from "../components/xMediaSuccessStories/StoriesTargets"
import { withNamespaces } from "react-i18next";

function xMediaSuccessStories() {
  return (
    <Layout>
      <SEO title="X Media Success Stories | Exsis Digital Angels" description={"Nearshore Agile Teams | Specialists in Tech Talent"} />
      <BannerXMmediaSuccessStories/>
      <StoriesTargets/>
    </Layout>
  )
}

// Como-revolucionaron-el-mercado-global-a-traves-de-la-tecnologia.js
export default withNamespaces()(xMediaSuccessStories)
